<script>
import {
  mapGetters,
} from 'vuex'

import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'

import SelectCategory from '@/views/components/SelectCategory.vue'
import ImageUploader from '@/views/components/ImageUplaoder.vue'

import messages from '@/libs/messages/toast'
import { BLink, BFormCheckbox, BSpinner } from 'bootstrap-vue'
import store from '@/store'
import itemsApi from '@/axios/api/items'
import toast from '@/libs/toast'
import addEditHelper from './add-edit'

/**
 * Shop-product-detail component
 */
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    SelectCategory,
    ImageUploader,
    BLink,
    BFormCheckbox,
    BSpinner,
  },

  middleware: 'redirect-if-not-authenticated',

  data() {
    return {
      formSubmitted: false,
      missingUserInfo: null,
      missingImages: false,
      invalidCategories: false,

      form: {
        title: null,
        description: null,
        categories: [],
        price1Day: null,
        price3Day: null,
        price7Day: null,
        images: [],
        returnGuarantees: '',
        branches: [],
      },

      title: null,
    }
  },

  computed: {
    user() {
      return store.getters['user/user']
    },
    ...mapGetters(['categories/flatCategories']),
  },

  mounted() {
    this.checkMissingUserInfo(this.user)
  },

  methods: {
    checkMissingUserInfo(user) {
      this.missingUserInfo = addEditHelper.checkMissingUserInfo(user)
    },

    checkCustomValidations() {
      this.invalidCategories = this.form.categories.length === 0
      this.missingImages = this.form.images.length === 0

      return this.invalidCategorires === false || this.missingImages === false
    },

    setSelectedCategory(category) {
      this.form.categories = category
      this.invalidCategorires = false
    },

    createAd() {
      this.formSubmitted = true
      this.checkCustomValidations()

      if (this.invalidCategories === true || this.missingImages === true) {
        this.formSubmitted = false
        return false
      }

      const formData = addEditHelper.getFormData(this.form)

      itemsApi.create(formData).then(() => {
        this.formSubmitted = false
        this.$router.push({
          name: 'list-ads',
        }).then(() => {
          toast.success(this.$bvToast, messages.success, messages.adCreated)
        })
      }).catch(() => {
        this.formSubmitted = false
        toast.error(this.$bvToast, messages.error, messages.errorDetails)
      })

      return true
    },

    imagesUpdated(images) {
      this.form.images = images
    },
  },
}
</script>

<template>
  <div>
    <div class="card border-0 rounded shadow col-md-9 p-2">
      <div class="row">
        <div class="col-12">
          <h1 class="py-1 mb-5 border-bottom">
            إضافة إعلان
          </h1>
        </div>
        <div class="col-md-9">
          <div
            v-if="missingUserInfo === true"
            class="alert alert-danger text-center py-3"
            role="alert"
            style="font-size: 1.3rem;"
          >
            لا توجد فروع مضافة حاليا, لابد من إضافة على الأقل فرع واحد
            <div class="d-inline-block w-100 text-center">
              <b-link
                :to="{name: 'account-settings'}"
                style="font-size: 1.3rem;"
                class="mt-3 btn btn-primary btn-sm mx-2"
              >
                الإعدادات
              </b-link>
            </div>
          </div>

          <ValidationObserver
            ref="createNewAd"
            #default="{ invalid }"
          >
            <form
              v-if="missingUserInfo === false"
              ref="createNewAd"
              @submit.prevent="createAd()"
            >
              <div class="row">
                <div class="col-md-12">
                  <div
                    class="form-group"
                    dir="rtl"
                  >
                    <h3 class="pb-1 font-weight-light">
                      <span class="badge-primary rounded-circle d-inline-block text-center badge-title">1</span>
                      <span class="mx-2">التصنيف</span>
                      <span class="text-danger">*</span>
                    </h3>
                    <SelectCategory
                      :invalid="invalidCategories === true"
                      @categorySelected="setSelectedCategory"
                    />
                    <div
                      v-if="invalidCategories === true"
                      class="text-danger smaller"
                    >
                      يجب
                      إختيار التصنيف
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mt-2 pt-2">
                  <div class="form-group">
                    <h3 class="pb-1 font-weight-light">
                      <span class="badge-primary rounded-circle d-inline-block text-center badge-title">2</span>
                      <span class="mx-2">تفاصيل الوحدة المعروضة</span>
                    </h3>
                    <label>العنوان</label>
                    <span class="text-danger">*</span>

                    <ValidationProvider
                      #default="{ errors }"
                      rules="required"
                      name="title"
                    >
                      <input
                        id="title"
                        v-model.trim="form.title"
                        name="title"
                        type="text"
                        class="form-control"
                      >
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </div>
                </div>
                <!--end col-->
                <div class="col-lg-12">
                  <label>الوصف</label>
                  <span class="text-danger">*</span>

                  <div class="form-group">
                    <ValidationProvider
                      #default="{ errors }"
                      rules="required"
                      name="description"
                    >
                      <textarea
                        id="description"
                        v-model.trim="form.description"
                        name="description"
                        rows="4"
                        class="form-control"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-lg-12 mt-2 pt-2">
                  <div class="form-group">
                    <h3 class="pb-1 font-weight-light">
                      <span class="badge-primary rounded-circle d-inline-block text-center badge-title">3</span>
                      <span class="mx-2">السعر</span>
                      <span class="text-danger">*</span>
                    </h3>
                    <div class="row">
                      <div class="col-md-4">
                        <label>ليوم واحد</label>
                        <ValidationProvider
                          #default="{ errors }"
                          rules="required"
                          name="price1Day"
                        >
                          <input
                            id="price1Day"
                            v-model.trim="form.price1Day"
                            name="title"
                            type="number"
                            class="form-control"
                          >
                          <small class="text-danger">{{ errors[0] }}</small>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-4">
                        <label>ل ٣ أيام</label>
                        <ValidationProvider
                          #default="{ errors }"
                          rules="required"
                          name="price3Day"
                        >
                          <input
                            id="price3Day"
                            v-model.trim="form.price3Day"
                            name="title"
                            type="number"
                            class="form-control"
                          >
                          <small class="text-danger">{{ errors[0] }}</small>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-4">
                        <label>لإسبوع</label>
                        <ValidationProvider
                          #default="{ errors }"
                          rules="required"
                          name="price7Day"
                        >
                          <input
                            id="price7Day"
                            v-model.trim="form.price7Day"
                            name="title"
                            type="number"
                            class="form-control"
                          >
                          <small class="text-danger">{{ errors[0] }}</small>
                        </ValidationProvider>
                      </div>
                      <div
                        class="col-12 d-inline-block mt-1 font-weight-light font-italic text-muted"
                        style="font-size: .8rem;"
                      >
                        <feather-icon
                          icon="InfoIcon"
                          class="smaller"
                        />
                        <span class="d-inline-block mx-2">
                          الأسعار يجب أن تكون باللغة الإنجليزية
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 mt-2 pt-2">
                  <div class="form-group">
                    <h3 class="pb-1 font-weight-light">
                      <span class="badge-primary rounded-circle d-inline-block text-center badge-title">4</span>
                      <span class="mx-2">ضمانات الاسترجاع</span>
                    </h3>
                    <textarea
                      id="returns"
                      v-model.trim="form.returnGuarantees"
                      name="returns"
                      rows="4"
                      class="form-control"
                      placeholder=""
                    />
                    <div
                      class="d-inline-block mt-1 font-weight-light font-italic text-muted"
                      style="font-size: .8rem;"
                    >
                      <feather-icon
                        icon="InfoIcon"
                        class="smaller"
                      />
                      <span class="d-inline-block mx-2">
                        تأكد من تحديد الضمانات اللازمة لاسترجاع وحدتك المعروضة
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 mt-2 pt-2">
                  <div class="form-group">
                    <h3 class="pb-1 font-weight-light">
                      <span class="badge-primary rounded-circle d-inline-block text-center badge-title">5</span>
                      <span class="mx-2">الأفرع المتاحة</span>
                      <span class="text-danger">*</span>
                    </h3>
                    <ValidationProvider
                      #default="{ errors }"
                      rules="required"
                      name="branches"
                    >
                      <div
                        v-for="(branch, index) in user.branches"
                        :key="index"
                        class="mx-2"
                      >
                        <b-form-checkbox
                          v-model="form.branches"
                          name="branches"
                          :value="branch.id"
                        >
                          {{ branch.name }}
                        </b-form-checkbox>
                      </div>
                      <small class="text-danger w-100 d-inline-block">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-lg-12 mt-2 pt-2">
                  <div class="form-group">
                    <h3 class="pb-1 font-weight-light">
                      <span class="badge-primary rounded-circle d-inline-block text-center badge-title">6</span>
                      <span class="mx-2">إمكانيةالشحن</span>
                    </h3>
                    <div class="mx-2">
                      <b-form-checkbox
                        v-model="form.shippable"
                        name="shippable"
                        :value="1"
                        :unchecked-value="0"
                      >
                        توجد إمكانية الشحن
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 mt-2 pt-2">
                  <div class="form-group">
                    <h3 class="pb-1 font-weight-light">
                      <span class="badge-primary rounded-circle d-inline-block text-center badge-title">7</span>
                      <span class="mx-2">إضافة صور</span>
                      <span class="text-danger">*</span>
                    </h3>
                    <ImageUploader
                      :max-images="5"
                      @imagesUpdated="imagesUpdated"
                    />
                    <div
                      class="d-inline-block mt-1 font-weight-light font-italic text-muted"
                      style="font-size: .8rem;"
                    >
                      <feather-icon
                        icon="InfoIcon"
                        class="smaller"
                      />
                      <span class="d-inline-block mx-2">
                        يجب إضافة على الأفل صورة واحدة وبحد أقصي 5 صور
                      </span>
                    </div>
                  </div>
                  <div
                    v-if="missingImages === true"
                    class="text-danger"
                  >
                    يجب على الإقل رفع
                    صورة واحدة
                  </div>
                </div>
              </div>
              <!--end row-->
              <div class="row">
                <div class="col-sm-12 mt-2 pt-2">
                  <button
                    id="submit"
                    :disabled="invalid || formSubmitted"
                    type="submit"
                    name="send"
                    class="btn btn-primary"
                  >
                    <BSpinner
                      v-if="formSubmitted"
                      small
                      class="mx-1"
                    />
                    إضافة العرض
                  </button>
                </div>
              <!--end col-->
              </div>
              <!--end row-->
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.vux-uploader_file {
  float: right !important;
}
.badge-title {
  width:30px;
  height:30px;
  padding-top: 5px;
}
</style>
